import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content from '../components/Content';
import PageHeader from '../components/PageHeader';
import { ImageType } from '../components/PreviewCompatibleImage';

type ProductsPageProps = {
  title: string;
  subtitle?: string;
  image: ImageType;
  content: string | JSX.Element | null;
};

export const ProductsPageTemplate = (props: ProductsPageProps): JSX.Element => {
  const { title, subtitle, image, content } = props;

  return (
    <div>
      <PageHeader title={title} subtitle={subtitle} image={image} />
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <Content className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const ProductsPage = (props: QueryData<GatsbyTypes.ProductsPageQuery>): JSX.Element => {
  const { data } = props;
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout>
      <ProductsPageTemplate
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        image={frontmatter.image}
        content={html}
      />
    </Layout>
  );
};

export default ProductsPage;

export const productsPageQuery = graphql`
  query ProductsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
